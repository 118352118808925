import $ from 'jquery';

$(() => {
    var tl;
    var timing = 1;
    var timingFunction = Power2.easeInOut;

    $.fn.appHeader = function( action = false ) {
        var $this = this;

        if(!action){
            tl = new TimelineLite();
            tl.to( $($this).find('#aperta'), timing, {
                ease: timingFunction,
                x: -240,
            }, '0')
            .to($($this).find('#last'), timing, {
                ease: timingFunction,
                x: 104,
            }, '0')
            .to($($this).find('#first'), timing, {
                ease: timingFunction,
                x: 104,
            }, '0')
            .to($($this).find('#musicaMask-path'), timing, {
                ease: timingFunction,
                scaleX: 0,
                x: '50%'
            }, '=-' + timing * 0.4)
            .to($($this).find('#musica'), 0.2 * timing, {
                opacity: 0
            }, '=-' + timing * 0.4 )
            .to($($this).find('#par-left'), 0.95 * timing, {
                ease: timingFunction,
                x: 121
            }, '=-' + timing)
            .to($($this).find('#par-right'), 0.95 * timing, {
                ease: timingFunction,
                x: -121
            }, '=-' + timing)
            .stop();
        }
 
        if ( action === "open") {
            $($this).removeClass('hide-links');
            tl.reverse();
        }
 
        if ( action === "close" ) {
            $($this).addClass('hide-links');
            tl.play();
        }

        return $this;
 
    };
})
import $ from 'jquery';
import onmount from 'onmount';

$(() => {
    var slideshows = {};
    onmount('[data-js-slideshow]', function (evt) {
        slideshows[evt.id] = $(this);
        $(slideshows[evt.id]).slick({
            arrows: false,
            autoplay: true,
            fade: false,
            autoplaySpeed: 6000,
            pauseOnFocus: false,
            pauseOnHover: false,
            speed: 1000,
            mobileFirst: true,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    fade: true,
                    arrows: true,
                }
            }]
        });
    }, function (evt) {
        $(slideshows[evt.id]).slick('unslick');
        slideshows[evt.id] = null;
    });
});

$(() => {
    var slideshows = {};
    onmount('[data-js-quote-slideshow]', function (evt) {
        slideshows[evt.id] = $(this);
        $(slideshows[evt.id]).slick({
            arrows: false,
            dots: true,
            autoplay: true,
            fade: true,
            autoplaySpeed: 8000,
            pauseOnFocus: false,
            pauseOnHover: false,
            speed: 1000,
            draggable: true
        });
    }, function (evt) {
        $(slideshows[evt.id]).slick('unslick');
        slideshows[evt.id] = null;
    });
})
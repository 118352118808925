import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
//import addIndicators from 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import onmount from 'onmount';

// Oscurezco la portada al scrollear
$(() => {
	let controller = new ScrollMagic.Controller();
    let scenes = {};
    
    const calcDuration = function(el){
        return $(el).innerHeight()
    }
    
	onmount('[data-js-portada]', function(evt){
		const $this = $(this);
		scenes[evt.id] = new ScrollMagic.Scene({
			triggerHook: 0,
			triggerElement: $($this).next()[0],
			duration: calcDuration($this) * 0.9,
			offset: -calcDuration($this)
		});
		//scenes[evt.id].addIndicators();
		scenes[evt.id].addTo(controller);
		scenes[evt.id].on("progress", function (event) {
			$($this)[0].style.opacity = 1 - event.progress
		});
	}, function(evt){
		controller.removeScene(scenes[evt.id]);
		scenes[evt.id] = null;
	})
});


$(()=>{
	$(document).on('click', '[data-js-click-scroll-down]', () => {
		let targetY = $(window).innerHeight() - 100;
		TweenLite.to(window, 1, {
			ease: Power4.easeInOut,
			scrollTo: {
				y: targetY
			}
		});
	});
});
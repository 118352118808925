import $ from 'jquery';

$(() => {
    var didScroll;
    var lastScrollTop = 0;
    var delta = 50;
    var $header = $('#header');
    var navbarHeight = $($header).innerHeight();
    var status = 'open';

    $($header).appHeader();

    $(header).hover(function(){
        $('#header').appHeader('open');
    }, function(){
        $('#header').appHeader(status || 'close');
    })

	$(window).scroll(function(event){
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(window).scrollTop();
        
        if(Math.abs(lastScrollTop - st) <= delta) return;
        
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            status = 'close';
            $('#header').appHeader(status);
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                status = 'open';
                $('#header').appHeader(status);
            }
        }
        
        lastScrollTop = st;
    }
});

$(() => {
	var History = window.History;
    History.Adapter.bind(window,'statechange',function(){
        $('#header .menu li').removeClass('active').find('a[href="' + window.location.pathname + '"]').parent().addClass('active');
    });
});
import $ from 'jquery';
import onmount from 'onmount';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';


$(() => {
    var slideshows = {};
    var $text = $('<span class="text">');
    var $pager = $('<span>');
    var $label = $('<p class="image-caption text-center">').append($pager).append($text);
    onmount('[data-js-carousel-gallery]', function(evt){
        slideshows[evt.id] = $(this);
        $(slideshows[evt.id]).on('click', function() {
            $('#header').appHeader('close');
        }).on('afterChange', function(e, slick, currentSlide){
            $($pager).html(`${currentSlide + 1}/${slick.$slides.length}`);
        }).on('init', function(e, slick){
            $($pager).html(`${1}/${slick.$slides.length}`);
            $($text).html(` → ${slick.$slider[0].dataset.label}`);
        });
        $(slideshows[evt.id]).slick({
            arrows: true,
            autoplay: false
        });
        $(slideshows[evt.id]).after($label);
    }, function(evt) {
        $(slideshows[evt.id]).slick('unslick');
        $(slideshows[evt.id]).off('click');
        slideshows[evt.id] = null;
    });
})
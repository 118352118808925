import $ from 'jquery';
import onmount from 'onmount';

$(() => {
	var History = window.History;
	onmount('#footer', function(evt){
		History.Adapter.bind(window,'statechange',function(){
			$('#footer .menu li').removeClass('active').find('a[href="' + window.location.pathname + '"]').parent().addClass('active');
		});
	});
});
import $ from 'jquery';
import onmount from 'onmount';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

$(() => {
    var slideshows = {};
    var gallery = null;
    var pswpElement = document.querySelectorAll('.pswp')[0];
    onmount('[data-js-open-gallery]', function(evt){
        $(this).on('click', function(){
            var $this = $(this);
            var $images = $($this).parents('.image-gallery').find('[data-js-image-gallery]');
            var items = []
            $($images).each((index, item)=>{
                items.push({
                    src: item.dataset.src,
                    w: item.dataset.width,
                    h: item.dataset.height
                })
            })
            var options = {
                index: 0,
                history: false,
                maxSpreadZoom: 1
            };
            gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        });
    }, function(evt) {
        
    });
})
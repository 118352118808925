import $ from 'jquery';
import onmount from 'onmount';
import TransitionFranja from './TransitionFranja';

var handleLink = function(_href){
	$('body').addClass('clicked-push-state');
	History.pushState(null, 'Musica Aperta', _href);
};
	
$(document).on('click', 'a:not(.no-ajaxy)', function(event){
	event.preventDefault();

	var $this = $(this);

	if ( event.which == 2 || event.metaKey ) {
		return true; 
	}

	if( $($this).parent().hasClass('logo') ){
		if(window.location.pathname === '/'){
			return TweenLite.to(window, 1, {
				ease: Power4.easeInOut,
				scrollTo: {
					y: 0
				}
			});
		}
	}

	if($($this).hasClass('franja2')){
		TweenLite.to($($this).parent(), 1.5, {
			ease: Power4.easeInOut,
			y: '+=' + ($(window).scrollTop() - $($this).offset().top),
			onStart: function() {
				$('#header').appHeader('open');
				$('body').addClass('prevent-scroll avoid-transition');
				TweenLite.to($this, 1.5, {
					ease: Power4.easeInOut,
					height: $('.portada').innerHeight()
				});
				TweenLite.to($($this).find('.-fadeout'), 0.5, {
					ease: Power4.easeInOut,
					opacity: 0
				});
			},
			onComplete: function(){
				$('body').removeClass('prevent-scroll');
				handleLink($($this).attr('href'));
			}
		});
	}else{
		handleLink($($this).attr('href'));
	}
});

// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import './AppHeader';
import onmount from 'onmount';
//import addIndicators from 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'lazysizes';
import Slick from 'slick-carousel';
import './AjaxifyLinks';
import './HistoryHandler';
import './Portada';
//import './Franja';
//import './Header';
import './Footer';
//import './FranjaExp';
import './Header2';
import './SlideShow';
import './CarouselGallery';
import './PhotoSwipe';

/* ========================================
	Init
/* ======================================== */
$(() => {
	onmount();
});
import $ from 'jquery';
import onmount from 'onmount';
import NProgress from 'nprogress';

$(() => {
    var History = window.History;
    History.Adapter.bind(window,'statechange',function(){
        // Analytics
        var d = document.location.pathname + document.location.search + document.location.hash;
        ga('send', 'pageview', d);
        
        var State = History.getState();
        $.ajax({
            method: 'GET',
            url: State.url,
            cache:true,
            beforeSend: function() {
                NProgress.configure({ showSpinner: false });
                NProgress.start();
            },
            success: function(data, textStatus, jqXHR){
                $('#header').removeClass('hide-links');

                let $content = $('#content');
                let $newData = $(data).filter('#content');
                
                let title = $(data).filter('title').html();
                $('title').html(title);
                console.log(title)
                
                if( $('body').hasClass('avoid-transition') ){
                    let $portada = $($newData).find('.portada');
                    let $image = $($portada).find('.object');
                    $($portada).addClass('avoid-blur');
                    $($image).removeClass('lazyload lazysizes').attr({
                        'src': $($image).data('src'),
                        'srcset': $($image).data('srcset')
                    });
                }
                
                $($content).html( $($newData).html() );
                onmount();

                if( !$('body').hasClass('avoid-transition') ){
                    TweenLite.from($content[0], 1, {
                        opacity: 0,
                    })
                }
                
                if(window.location.pathname !== '/'){
                    TweenLite.to(window, 1, {
                        scrollTo: $(window).innerHeight() * 0.5,
                        delay: 0.5
                    });
                }
                
                if( $('body').hasClass('clicked-push-state') ){
                    $('body').removeClass('clicked-push-state avoid-transition');
                    $(window).scrollTop(0);
                }
                NProgress.done();
            }
        });
    });
});
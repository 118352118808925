import $ from 'jquery';

export default class TransitionFranja {
    static to($target){
        const $franja = $($target).parents('.franja').css({overflow:'hidden', zIndex: 99});
        const top = $($franja).offset().top - $(window).scrollTop();
        var tl = new TimelineLite();
        $('body').addClass('prevent-scroll');
        return new Promise((resolve, reject) => {
            tl
            .to(window, 1, {
                ease: Power2.easeInOut,
                scrollTo: {
                    y: $(window).scrollTop() - (100 - ($($franja).offset().top - $(window).scrollTop()))
                }
            })
            .to($($franja).find('.fade-out'), 1 , {
                opacity: 0
            }, '-=1')
            .to($franja, 1, {
                y: -100,
                height: $(window).innerHeight(),
                ease: Power2.easeInOut,
                onComplete: () => {
                    $('body').removeClass('prevent-scroll');
                    return resolve();
                }
            })
        })
    }
}